<template>
  <b-form-group
    class="phone-field m-0"
    :class="{'phone-field-error': isRequired || (phoneObj.phone !== '' && !isValid) || isErrorPhoneNumber}"
  >
    <div class="d-flex justify-content-between">
      <label class="form-label">
        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelPhoneNumber') }} <span class="text-danger">*</span>
      </label>
    </div>
    <int-tel-input
      id="verify-phone-number"
      data-testid="verify-phone-number"
      :phone="phoneObj.phone"
      :country-abbr="phoneObj.countryAbbr"
      :placeholder="$t('stepGuideControl.placeholderPhoneNumber')"
      @country-change="countryCodeChange"
      @phone-change="phoneNumberChange"
    />
    <small
      v-if="isRequired || isErrorPhoneNumber"
      data-testid="error-required-phone-number"
      class="text-danger"
    >
      {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.errorPhoneNumberRequired') }}
    </small>
    <small
      v-if="phoneObj.phone !== '' && !isValid"
      data-testid="error-invalid-phone-number"
      class="text-danger"
    >
      {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.errorPhoneNumberInvalid') }}
    </small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import IntTelInput from 'int-tel-input'

export default {
  components: {
    BFormGroup,
    IntTelInput,
  },

  props: {
    phoneData: {
      type: Object,
      required: true,
      default: () => {
      },
    },

    isErrorPhoneNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      phoneObj: {
        countryAbbr: 'vn',
        countryCode: '',
        phone: '',
        isValid: true,
        isRequired: false,
      },
    }
  },

  computed: {
    isRequired() {
      return this.phoneObj.isRequired
    },
    isValid() {
      return this.phoneObj.isValid
    },
  },

  watch: {
    phoneData: {
      handler(val) {
        this.phoneObj = val
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    countryCodeChange(country) {
      this.phoneObj.countryAbbr = country.addr
      this.phoneObj.countryCode = country.code
      this.$emit('update:phone-data', this.phoneObj)
    },
    phoneNumberChange(phone) {
      this.checkValid(phone)
    },
    checkValid(phone) {
      this.phoneObj.phone = phone
      if (phone) {
        this.phoneObj.isRequired = false
        const regExp = /^[0-9]*$/
        const regSpc = /\s/
        if (regExp.test(phone) && !regSpc.test(phone) && phone.length <= 14 && phone.length > 2) {
          this.phoneObj.isValid = true
          this.$emit('update:phone-data', this.phoneObj)
        } else {
          this.phoneObj.isValid = false
          this.$emit('update:phone-data', this.phoneObj)
        }
      } else {
        this.phoneObj.isRequired = true
      }
    },
  },
}
</script>

<style lang="scss">
  .phone-field {

    .int-tel-input-wapper .int-tel-input input, .int-tel-input-wapper .int-tel-select > input {
      height: 48px !important;
      border: 1px solid #e0e0e5;

      &::-webkit-input-placeholder {
        font-size: 16px;
        color: #B9B9C3;
      }
    }

    .int-tel-input-wapper .int-tel-select > input {
      /* border-bottom-left-radius: .625rem;
      border-top-left-radius: .625rem; */
      border-bottom-left-radius: var(--input-border-radius-base);
      border-top-left-radius: var(--input-border-radius-base);
    }

    .int-tel-input-wapper .int-tel-input input {
      border-left: 0;
      /* border-bottom-right-radius: .625rem;
      border-top-right-radius: .625rem; */
      border-bottom-right-radius: var(--input-border-radius-base);
      border-top-right-radius: var(--input-border-radius-base);
    }

    .int-tel-input-wapper .select-dropdown .scrollbar__wrap .scrollbar li.options .int-countries-flag {
      display: none !important;
    }

    .int-tel-input-wapper .int-tel-input input:hover, .int-tel-input-wapper .int-tel-select > input:hover{
      border-color: #e0e0e5;
      box-shadow: none;
    }

    .int-tel-input-wapper .int-tel-input input:focus {
      border-color: #16213E;
      box-shadow: none;
      border-left: 1px solid #16213E;
    }

    .int-tel-input-wapper .int-tel-select > input:focus {
      border-color: #16213E;
      box-shadow: none;
    }

    .int-tel-input-wapper .select-dropdown .scrollbar__wrap .scrollbar li.options{
      color: #16213E !important;
      padding: 10px;
      font-size: 14px;
    }

    .int-tel-input-wapper .select-dropdown .scrollbar__wrap .scrollbar li.options:hover{
      background-color: #fcf7f8;
    }

    .int-tel-input-wapper .select-dropdown .scrollbar__wrap .scrollbar li.options.selected {
      background-color: #fcf7f8;
    }

    .filter-alphabet {
      display: none;
    }
  }

  .phone-field-error{
    .int-tel-input-wapper .int-tel-input input {
      border: 1px solid #E11C4E !important;
    }
  }

</style>
