<template>
  <div
    ref="error-tag"
    data-testid="error-message-container"
    class="error-message-container"
  >
    <div
      data-testid="error-message"
      class="error-message"
    >
      <div
        data-testid="error-icon-container"
        class="d-flex align-items-center"
      >
        <img
          data-testid="icon-error"
          style="margin-right: 8px"
          alt="alert"
          :src="require('@/assets/images/common/ic-warning-red.svg')"
        >
        <p
          data-testid="text-error"
          class="m-0 font-weight-600"
        >
          {{ $t('common.textError') }}:
        </p>
      </div>
      <p
        data-testid="content-error"
        class="content-error"
        v-html="error"
      />

      <slot name="errorMessenger" />
    </div>
  </div>
</template>

<script>

export default {

  props: {
    error: {
      default: '',
      type: String,
    },
  },

  watch: {
    error: {
      handler(err) {
        if (err && this.$refs) {
          this.$nextTick(() => {
            this.scrollToErrorCard()
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    scrollToErrorCard() {
      this.$refs['error-tag'].scrollIntoView({ behavior: 'smooth' })
    },
  },
}
</script>

<style scoped lang="scss">
.error-message-container{
  width: 100%;
  margin: 24px 0 0;
  background: rgba(225, 28, 78, .1);
  border-radius: 20px;
  padding: 1rem;
  color: #000;

  .content-error {
    margin-left: 6px;
    margin-bottom: 0;
  }
}
</style>
