<template>
  <b-col
    lg="5"
    class="d-none d-lg-flex align-items-center px-0 banner w-100"
  >
    <div
      class="w-100 d-lg-flex align-items-center justify-content-center left-image p-0"
    >
      <swiper
        v-if="isEcomdyPlatform"
        class="swiper m-0 w-100"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(imgSlide, index) in arrImgSlideEcomdy"
          :key="index"
        >
          <b-img
            class="position-absolute bg-banner-login"
            fluid
            alt="banner login"
            :src="imgSlide.imgEcomdy"
          />

          <!--          <a-->
          <!--            href="https://blog.ecomdymedia.com/blog/2024-tiktok-agency-ad-account-kickstarter-with-ecomdy"-->
          <!--            class="bottom-banner-content"-->
          <!--            target="_blank"-->
          <!--          >Learn more >>></a>-->
        </swiper-slide>
      </swiper>
      <swiper
        v-else
        class="swiper m-0 w-100"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(imgSlide, index) in arrImgSlide"
          :key="index"
          :style="{backgroundImage:`url(${imgSlide.img})`}"
        >
          <!--            <b-img-->
          <!--              class="avt"-->
          <!--              fluid-->
          <!--              :alt="imgSlide.alt"-->
          <!--              :src="imgSlide.content"-->
          <!--            />-->
          <div class="content-swiper">
            <h1 class="title-content">
              Increase Traffic and Sales
            </h1>
            <h3 class="title-detail-content">
              <b-img
                class="icon-check"
                fluid
                alt="icon check"
                src="@/assets/images/pages/auth/ic-check-white.svg"
              />
              Whitelist TikTok Ad Account
            </h3>
            <p class="sub-content">
              Easy to launch your ads campaign with an unlimited budget
            </p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </b-col>
<!--  <b-col-->
<!--    class="d-none d-lg-flex px-0 banner w-100"-->
<!--  >-->
<!--    <b-img-->
<!--      class="vh-100 w-100"-->
<!--      fluid-->
<!--      alt="imgSlide"-->
<!--      src="@/assets/images/pages/auth/background-left.png"-->
<!--    />-->

<!--    <div class="text-white position-absolute text-banner">-->
<!--      <h4 class="font-32 text-white">-->
<!--        Increase Traffic and Sales-->
<!--      </h4>-->
<!--      <div class="d-flex align-items-start margin-content">-->
<!--        <b-img-->
<!--          class="margin-icon"-->
<!--          fluid-->
<!--          alt="icon check"-->
<!--          src="@/assets/images/pages/auth/ic-check-white.svg"-->
<!--        />-->
<!--        <div>-->
<!--          <p class="font-20 mb-0 font-weight-600">-->
<!--            Whitelist TikTok Ad Account-->
<!--          </p>-->
<!--          <p class="mb-0">-->
<!--            Easy to launch your ads campaign with an unlimited budget-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="d-flex align-items-start margin-content">-->
<!--        <b-img-->
<!--          class="margin-icon"-->
<!--          fluid-->
<!--          alt="icon check"-->
<!--          src="@/assets/images/pages/auth/ic-check-white.svg"-->
<!--        />-->
<!--        <div>-->
<!--          <p class="font-20 mb-0 font-weight-600">-->
<!--            Flexible payment-->
<!--          </p>-->
<!--          <p class="mb-0">-->
<!--            Accept Credit Card, Payoneer, Paypal, USDT and more-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="d-flex align-items-start margin-content mb-2">-->
<!--        <b-img-->
<!--          class="margin-icon"-->
<!--          fluid-->
<!--          alt="icon check"-->
<!--          src="@/assets/images/pages/auth/ic-check-white.svg"-->
<!--        />-->
<!--        <div>-->
<!--          <p class="font-20 mb-0 font-weight-600">-->
<!--            24/7 support-->
<!--          </p>-->
<!--          <p class="mb-0">-->
<!--            Ads campaign review and direct support-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="d-flex justify-content-center">-->
<!--        <b-img-->
<!--          class="img-bg-left"-->
<!--          fluid-->
<!--          alt="imgSlide"-->
<!--          src="@/assets/images/pages/auth/bg-left-login.png"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--  </b-col>-->
</template>

<script>
import { BImg, BCol } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import textSlide1 from '@/assets/images/pages/auth/text-slide-1.png'
import textSlide2 from '@/assets/images/pages/auth/text-slide-2.png'
import textSlide3 from '@/assets/images/pages/auth/text-slide-3.png'

import slide1 from '@/assets/images/pages/auth/img-slide-1-ver-2023.jpeg'
import slide2 from '@/assets/images/pages/auth/img-slide-2-ver-2023.jpeg'
import slide3 from '@/assets/images/pages/auth/img-slide-3-ver-2023.jpeg'
import slide4 from '@/assets/images/pages/auth/banner-register-2024.png'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    BImg,
    BCol,
    Swiper,
    SwiperSlide,
  },

  mixins: [envMixin],

  data() {
    return {
      swiperOptions: {
        effect: 'fade',
        spaceBetween: 20,
        centeredSlides: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      arrImgSlide: [
        {
          alt: 'image slide 1',
          img: process.env.VUE_APP_LOGIN_SLIDE_1 || slide1,
          content: process.env.VUE_APP_LOGIN_SLIDE_CONTENT_1 || textSlide1,
        },
        {
          alt: 'image slide 2',
          img: process.env.VUE_APP_LOGIN_SLIDE_2 || slide2,
          content: process.env.VUE_APP_LOGIN_SLIDE_CONTENT_2 || textSlide2,
        },
        {
          alt: 'image slide 3',
          img: process.env.VUE_APP_LOGIN_SLIDE_3 || slide3,
          content: process.env.VUE_APP_LOGIN_SLIDE_CONTENT_3 || textSlide3,
        },
      ],

      arrImgSlideEcomdy: [
        {
          alt: 'image slide 4',
          imgEcomdy: process.env.VUE_APP_LOGIN_SLIDE_4 || slide4,
          // content: process.env.VUE_APP_LOGIN_SLIDE_CONTENT_1 || textSlide1,
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.banner {
  // margin: 28px;
  .left-image {
    max-height: 100%;
    height: 100%;
  }
  .swiper-container {
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .bg-banner-login {
        height: 100%;
        max-width: 200%;
        right: 0;
      }
    }
  }
}

.margin-icon {
  margin-right: 14px;
}

.margin-content {
  margin-top: 32px;
}

.text-banner {
  margin: 73px 20% 73px 15%;
}

.img-bg-left {
  margin-top: 0.5rem;
  width: 75%;

  @media (min-width:1540px) {
    margin-top: 4rem;
    width: 100%;
  }
}
.content-swiper{
  padding: 38px 50px 38px 174px;
  color: #fff;

  @media (min-width: 768px) and (max-width: 1292px) {
    padding: 38px 50px 38px 50px;
  }
  @media (min-width: 1293px) and (max-width: 1500px) {
    padding: 38px 50px 38px 142px;
  }

  .title-content{
    font-size: 32px;
    color: #fff;
  }

  .title-detail-content{
    color: #fff;
    font-size: 20px;

    .icon-check{
      padding: 0 !important;
    }
  }

  .sub-content{
    margin-left: 27px;
  }
}

.bottom-banner-content {
  color: #fff;
  position: absolute;
  bottom: 20px;
  right: 30px;
  text-decoration-line: underline;
}
</style>
